/*
IMPORTANTE: No editar este archivo en noname-base-frontend 
debido a que al copiar el modulo este lo reemplazara.
*/

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "es";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  //  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const currentUser = {
  id: 1,
  title: "Nuria Sanz",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin,
};

export const adminRoot = "/app";
export const loginRoot = "/user/login";

export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = "https://api.coloredstrategies.com";
export const permissionDenied = "/app";

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = false;
export const defaultColor = "light.motorfacturacion";
export const isDarkSwitchActive = false;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = false;
export const colors = [
  /*
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
  */
  "bluenoname",
  "pinknoname",
  "greennoname",
  "orangenoname",
];

export const adminMenus = [""];
export const customFilter = "";
